import { render, staticRenderFns } from "./yg.vue?vue&type=template&id=28eb36af&scoped=true&"
import script from "./yg.vue?vue&type=script&lang=js&"
export * from "./yg.vue?vue&type=script&lang=js&"
import style0 from "./yg.vue?vue&type=style&index=0&id=28eb36af&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28eb36af",
  null
  
)

export default component.exports